import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollEventService {
  scrollEvent: EventEmitter<any> = new EventEmitter<any>();

  emitScrollEvent(scrollPosY: any) {
    this.scrollEvent.emit(scrollPosY);
  }
}
