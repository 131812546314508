import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public currentPage: string = '';
  public attrs: string | undefined = '';
  public attrsKey: string | undefined = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe(async (route) => {
        this.currentPage = (await firstValueFrom(route.data))['page'];
      });
  }

  public getLangShortText(lang: string) {
    switch (lang) {
      case 'fr-FR':
        return 'fr';
      case 'fr-CA':
        return 'fr-ca';
      case 'en-GB':
        return 'en';
    }

    return 'en';
  }

  public updateCurrentUrl() {
    if (this.currentPage) {
      let url = this.getRoute(this.currentPage);
      if (this.attrsKey) {
        let attrs = this.getRouteByKey(this.currentPage, this.attrsKey);
        url += '/' + attrs;
      } else if (this.attrs) {
        url += '/' + this.attrs;
      }
      this.router.navigateByUrl(url);
    }
  }

  public getRouteByKey(page: string, key: string) {
    const localKey = page + '.' + key + '.metadata.path';
    try {
      const localePage = this.translateService.instant(localKey);
      if (localePage && localePage !== localKey) {
        page = localePage;
      }
    } catch (error) {
      // Use page as path
    }
    return page;
  }

  public getRoute(page: string) {
    const langPath = this.getLangShortText(this.translateService.currentLang);
    const key = page + '.metadata.path';

    try {
      const localePage = this.translateService.instant(key);

      if (localePage && localePage !== key) {
        page = localePage;
      }
    } catch (error) {
      // Use page as path
      console.log(error);
    }

    let url = '/' + langPath;
    if (page !== 'home' && page?.length > 0) {
      if (page.startsWith('/')) {
        url += page;
      } else {
        url += '/' + page;
      }
    }

    return url;
  }

  public getPath(page: string, attrs?: string, attrsKey?: string) {
    const route = this.getRoute(page);
    this.attrsKey = attrsKey;
    this.attrs = attrsKey ? this.getRouteByKey(page, attrsKey) : attrs;

    return this.attrs ? [route, this.attrs] : [route];
  }

  public async navigateTo(page: string, attrs?: string, attrsKey?: string) {
    await this.router.navigate(this.getPath(page, attrs, attrsKey));
  }
}
